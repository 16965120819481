<template>
  <span class="activity-cancel-button" v-if="canCancel">
    <a href @click.prevent="showModal" class="btn btn-xs btn-secondary">
      <i class="fas fa-close"></i>
      Annuler
    </a>
    <b-modal
      dialog-class="modal-lg"
      :id="getId()"
      cancel-title="Non"
      @ok.prevent="onSave"
      ok-variant="primary"
      ok-title="Oui, Confirmer l'annulation"
    >
      <template v-slot:modal-title>
        <b><i class="fas fa-close"></i> Annuler une inscription à une activité</b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <div class="confirm-text">
        Souhaitez-vous annuler l'inscription de {{ inscription.individual.firstAndLastName() }} à
        l'activité {{ inscription.activity.name }} {{ inscription.activity.schoolYear.name }} ?
      </div>
      <b-row>
        <b-col>
          <b-row class="">
            <b-col>
              <b-form-group
                id="refund-group"
                label-for="refund"
                description="Si vous décochez la case, la famille devra payer l'inscription même si elle est annulée"
              >
              <b-checkbox
                  id="refund"
                  v-model="refund"
                  :disabled="inscription.invoice === null"
                >
                  Annulation non payante
                </b-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { getRandomId } from '@/utils/random'
import { BackendApi } from '@/utils/http'

export default {
  name: 'activity-cancel-button',
  mixins: [BackendMixin],
  props: {
    youthHome: [String, Number],
    inscription: Object,
    entity: Object,
  },
  components: {
  },
  data() {
    return {
      randomId: getRandomId(),
      refund: true,
      errorText: '',
    }
  },
  computed: {
    canCancel() {
      if (this.youthHome) {
        return this.hasPerm('youth.delete_youthhomeactivityinscription')
      } else {
        return this.hasPerm('activities.delete_coreactivityinscription')
      }
    },
  },
  watch: {
    inscription: function(newValue, oldValue) {},
    entity: function(newValue, oldValue) {},
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    showModal() {
      this.$bvModal.show(this.getId())
    },
    getId() {
      return 'bv-modal-activity-cancel-button' + this.randomId
    },
    async onSave() {
      this.errorText = ''
      try {
        let url = '/api/entity/' + this.entity.id + '/inscriptions/' + this.inscription.id + '/cancel/'
        if (this.youthHome) {
          url = '/api/entity/' + this.entity.id + '/youth/activity-inscriptions/' + this.inscription.id + '/cancel/'
        }
        const backendApi = new BackendApi('patch', url)
        let data = {
          refund: this.refund,
        }
        await backendApi.callApi(data)
        this.$bvModal.hide(this.getId())
        if (this.refund) {
          await this.addSuccess('L\'inscription a été annulée et remboursée')
        } else {
          await this.addSuccess('L\'inscription a été annulée sans remboursement')
        }
        this.$emit('done')
      } catch (err) {
        this.errorText = this.getErrorText(err)
      }
    },
  },
  mounted() {
  },
}
</script>
<style lang="less">
  .error-text {
    padding: 20px;
    color: #cc3700;
    background: #e0e0e0;
  }
  .confirm-text {
    font-weight: bold;
  }
</style>
